import React, { FC } from 'react';
import { Link } from 'gatsby';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { makeStyles } from '@material-ui/core';
import SadWaiterSVG from '../../icons/sad_waiter_404_2.svg';
import AppPageHeader from './texts/appPageHeader';
import AppButton from './button';

const useStyles = makeStyles({
	notFoundWrapper: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
	},
	imgWrapper: {
		padding: '45px 0 60px',
		textAlign: 'center',
	},
});

interface NotFoundProps {
    text?: string;
    buttonText?: string;
    buttonLink?: string;
}

export const NotFound: FC<NotFoundProps> = ({ text, buttonText, buttonLink }) => {
	const classes = useStyles();

	return (
		<div className={classes.notFoundWrapper}>
			<AppPageHeader>{text}</AppPageHeader>
			<div className={classes.imgWrapper}>
				<SadWaiterSVG />
			</div>
			<AppButton
				as={Link}
				to={buttonLink}
			>
				{buttonText}
			</AppButton>
		</div>
	);
};

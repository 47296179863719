import React from "react";
import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import { NotFound } from "../components/common/notFound";
import { LINKS } from "../constants/links";
import { AppContainer } from "../components/common/appContainer";

const NotFoundPage = () => {
	const { t } = useTranslation();

	return (
		<>
			<SEO title={t("notFound.title")} />
			<AppContainer>
				<NotFound buttonLink={LINKS.HOMEPAGE} buttonText={t("common.homepage")} text={t("notFound.header")} />
			</AppContainer>
		</>
	);
};

export default NotFoundPage;
